
body {
    background: white;
    padding-top: 110px;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 1200px) {
    body {
        padding-top: 156px;
    }
}

a:hover, a:active, a:focus {
    outline: none;
    text-decoration: none;
}

@media (min-width: 1200px) {
    .container {
        max-width: 1250px;
    }
}

.custom-button {
    color: #fff !important;
    border-radius: 50px;
    padding: 1rem 2.5rem !important;
    margin: 0 10px;
    display: inline-block;
}

.custom-button.button-outline {
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    font-size: 26px;
    min-width: 270px;
}

.custom-button.button-red {
    background: #be1e2d;
}

.custom-button.button-red.button-outline {
    border: 3px solid #be1e2d;
    color: #be1e2d !important;
    background: none;
}

.custom-button.button-black {
    background: #000;
}

.custom-button.button-black.button-outline {
    border: 3px solid #000;
    color: #000 !important;
    background: none;
}

.custom-button.button-blue {
    background: #2e3191;
}

.custom-button.button-blue.button-outline {
    border: 3px solid #2e3191;
    color: #2e3191 !important;
    background: none;
}

.text-red {
    color: #be1e2d;
}

.text-blue {
    color: #2e3191;
}

.red {
    color: #be1e2d;
}

.blue {
    color: #2e3191;
}

.blue-bg, .red-bg:hover {
    background-color: #2e3191;
    border-color: #2e3191;
}

.red-bg, .blue-bg:hover {
    background-color: #be1e2d;
    border-color: #be1e2d;
}

.section-container {
    padding: 80px 0;
}

@media (min-width: 768px) {
    .section-container {
        padding: 80px 0;
    }
}

@media (min-width: 992px) {
    .section-container {
        padding: 140px 0;
    }
}

.section-container .section-title {
    text-align: center;
    margin: 40px 0;
}

@media (min-width: 1200px) {
    .section-container .section-title {
        text-align: center;
        margin: 40px 0 100px;
    }
}
