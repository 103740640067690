.how-it-works-section .item-wrapper {
    padding: 0 30px;
}

.how-it-works-section .item-wrapper .icon {
    font-size: 22px;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    padding: 13px;
    text-align: center;
    color: #fff;
    margin: 0 auto;
}

.how-it-works-section .item-wrapper.first .icon {
    background: #282828;
    border: 1px solid #282828;
}

.how-it-works-section .item-wrapper.second .icon {
    background: #851c1c;
    border: 1px solid #851c1c;
}

.how-it-works-section .item-wrapper.third .icon {
    background: #112d70;
    border: 1px solid #112d70;
}

.how-it-works-section .item-wrapper .title {
    font-family: Montserrat, sans-serif;
    font-size: 22px;
}

.how-it-works-section .item-wrapper.first .title {
    color: #282828;
}

.how-it-works-section .item-wrapper.second .title {
    color: #851c1c;
}

.how-it-works-section .item-wrapper.third .title {
    color: #112d70;
}

.how-it-works-section .item-wrapper .subtitle {
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    color: #777777;
}

.how-it-works-section .highlight-text {
    font-family: "Montserrat", serif;
    font-size: 22px;
    color: #222;
    padding: 20px 0;
}