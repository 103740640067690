.what-we-offer-section .item-wrapper {
    padding: 0 30px;
}

.what-we-offer-section .item-wrapper .icon {
    font-size: 22px;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    padding: 13px;
    text-align: center;
    color: #fff;
    margin: 0 auto;
    background: #0e2967;
    border: 1px solid #0e2967;
}

.what-we-offer-section .item-wrapper .title {
    font-family: Montserrat, sans-serif;
    font-size: 22px;
    color: #444444;
    text-transform: uppercase;
}

.what-we-offer-section .item-wrapper .subtitle {
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    color: #777777;
}