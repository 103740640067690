.upper-header-wrapper {
    background: #000;
    color: #fefeff;
    font-family: "Roboto", sans-serif;
    font-size: 20px;
}

.upper-header-wrapper a,
.upper-header-wrapper a:hover,
.upper-header-wrapper a.nav-link,
.upper-header-wrapper a.nav-link:hover {
    color: #fefeff;
    padding: 0;
}

.upper-header-wrapper .row {
    padding: .5rem 1rem;
}

.main-header {
    background: #fff;
}

.custom-navbar {
    background: #fff;
    padding: 1.5rem 0 1rem;
}

.custom-navbar .navbar-nav .nav-item a.nav-link {
    font-family: "Montserrat", serif;
    text-transform: uppercase;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    padding: 1rem;
}

@media (min-width: 768px) {
    .custom-navbar .navbar-nav .nav-item a.nav-link {
        padding-right: 1.25rem;
        padding-left: 1.25rem;
    }

    .custom-navbar .navbar-nav .nav-item {
        text-align: center;
    }

    .custom-navbar .navbar-nav .nav-item a.custom-button {
        margin: 0.5rem 10px;
    }
}

@media (max-width: 468px) {
    .custom-navbar .navbar-nav .nav-item {
        text-align: center;
    }

    .custom-navbar .navbar-nav .nav-item a.custom-button {
        margin: 0.5rem 0;
    }
}

@media (min-width: 1200px) {
    .navbar-expand-custom {
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
    }

    .navbar-expand-custom .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-custom .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem;
    }

    .navbar-expand-custom .navbar-collapse {
        display: flex !important;
    }

    .navbar-expand-custom .navbar-toggler {
        display: none;
    }

    .custom-navbar .navbar-nav .nav-item a.custom-button {
        margin: 0 10px;
    }
}

.custom-navbar:after {
    content: "";
    position: absolute;
    z-index: -1;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
    bottom: 0;
    left: 10%;
    right: 10%;
    width: 80%;
    height: 50%;
    -moz-border-radius: 100%;
    border-radius: 100%;
}